<template>
  <content-wrapper class="objects-screen">
    <div class="tags">
      <swiper :options="{slidesPerView: 'auto', touchRatio: 2}">
        <swiper-slide>
          <tag title="Все" :active="$route.params.type == 0" @click.native="changeType(0)" />
        </swiper-slide>
        <swiper-slide v-for="(tag, index) in objectTypes" :key="index">
          <tag
            :title="tag.name"
            :active="tag.id==$route.params.type"
            @click.native="changeType(tag.id)"
          />
        </swiper-slide>
      </swiper>
    </div>

    <div class="objects">
      <object-card
        class="object"
        v-for="object in objects"
        :key="object.id"
        type="icon"
        width="488px"
        height="275px"
        :img="object.images[Object.keys(object.images)[0]].replace('extra','small')"
        :title="object.title"
        :category="object.type.name"
        :icon="object.type.image.replace('extra','small')"
        @click.native="goToObject(object)"
      />
    </div>
  </content-wrapper>
</template>


<script>
import { mapGetters } from "vuex";
import ContentWrapper from "@/components/Wrappers/ContentWrapper";
import Tag from "@/components/Parts/Tag";
import ObjectCard from "@/components/Parts/ObjectCard";

export default {
  components: { ContentWrapper, Tag, ObjectCard },
  computed: {
    ...mapGetters(["objectTypes", "objects"])
  },
  mounted() {
    console.log(this.$route.params.type, this.$route.params.city);
    this.fetchData();
  },
  watch: {
    $route() {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      this.$store.dispatch("fetchObjectTypes");
      this.$store.dispatch("fetchObjects", {
        type: this.$route.params.type,
        city: this.$route.params.city
      });
    },
    changeType(type) {
      if (this.$route.params.type !== type) {
        this.$router.replace({
          path: `/objects/${this.$route.params.city}/${type}`
        });
        this.fetchData();
      }
    },
    goToObject(object) {
      this.$store.dispatch("setSelectedObject", object);
      this.$router.push({ path: `/object/${object.id}` });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.objects-screen {
  position: relative;
  .objects {
    padding: 56px 32px;
    padding-bottom: 176px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .object {
      width: 488px;
      margin-bottom: 48px;
    }
  }
  .tags {
    width: 100%;
    position: fixed;
    z-index: 10;
    bottom: 168px;
    left: 0;
    background: $color_grey_2;
    // border-top: 4px solid rgba(#fff, 0.16);
    &::after {
      // border
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: rgba(#fff, 0.16);
    }
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 168px;
      bottom: 56px;
      z-index: -1;
      // z-index: 100;
      background-image: linear-gradient(
        to bottom,
        rgba($color_grey_2, 0),
        $color_grey_2 50%
      );
      // background: red;
    }
  }
}
</style>